import React, { useEffect, useRef } from 'react';

import './assets/scss/themes.scss';
import Route from './Routes';
import { useDispatch, useSelector } from 'react-redux';
import { updateWorkflows } from 'slices/workflow/reducer';
import { updateTickets } from 'slices/tickets/reducer';


function App() {

  const dispatch = useDispatch()

  const { workflows, loading } = useSelector((state: any) => state.Workflow); // Adjust to your root reducer structure
  const { tickets, loadingTickets } = useSelector((state: any) => state.Ticket); // Adjust to your root reducer structure
  const isConnected: any = useRef()
  const isTicketConnected: any = useRef()

  // Workflow SSE
  useEffect(() => {
    if (workflows && !loading) {
      // Call SSE 
      const token = sessionStorage.getItem("authToken");

      if (!token) {
        console.log("No auth token found")
        return
      }

      // Prevent multiple connections
      if (isConnected.current) return;

      const fetchData = async () => {
        try {
          // Start the fetch request to the SSE endpoint
          const response = await fetch(`${window.env.REACT_APP_BASE_URL}/workflow/status`, {
            headers: {
              'Cache-Control': 'no-cache',
              Accept: 'text/event-stream',
              Authorization: `Bearer ${token}`
            }
          });

          // If the connection is successful, mark it as connected
          if (response.ok && response.status === 200) {
            isConnected.current = true;
          } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
            console.error("Client-side error", response);
            return;
          }

          // Get the reader for the response body
          const reader = response.body?.getReader();
          if (!reader) return;

          const decoder = new TextDecoder();

          while (true) {
            const { value, done } = await reader.read();
            if (done) break;

            // Decode the chunk of data received from the stream
            const chunk = decoder.decode(value, { stream: true });

            // Split the chunk by lines to handle individual messages
            const events = chunk.split('\n').filter(Boolean);
            for (const event of events) {
              if (event.startsWith('data: ')) {
                const message = event.substring('data: '.length).trim();

                try {
                  // Check if the message is using single quotes and convert to double quotes if needed
                  const validJsonMessage = message.replace(/'/g, '"');

                  // Parse the JSON data
                  const parsedData = JSON.parse(validJsonMessage);

                  // Update in-progress workflows
                  dispatch(updateWorkflows(parsedData))
                } catch (error) {
                  console.error("Error parsing SSE data:", error);
                }
              }
            }
          }
        } catch (error) {
          console.error("Error connecting to SSE:", error);
        } finally {
          // Reset connection status
          isConnected.current = false;
        }
      };

      fetchData();

      // Cleanup function to reset connection status on unmount
      return () => {
        isConnected.current = false;
      };

    }
  }, [loading])

  // Ticket SSE
  useEffect(() => {
    if (tickets && !loadingTickets) {
      // Call SSE 
      const token = sessionStorage.getItem("authToken");

      if (!token) {
        console.log("No auth token found")
        return
      }

      // Prevent multiple connections
      if (isTicketConnected.current) return;

      const fetchData = async () => {
        try {
          // Start the fetch request to the SSE endpoint
          const response = await fetch(`${window.env.REACT_APP_BASE_URL}/tickets/status`, {
            headers: {
              'Cache-Control': 'no-cache',
              Accept: 'text/event-stream',
              Authorization: `Bearer ${token}`
            }
          });

          // If the connection is successful, mark it as connected
          if (response.ok && response.status === 200) {
            isTicketConnected.current = true;
          } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
            console.error("Client-side error", response);
            return;
          }

          // Get the reader for the response body
          const reader = response.body?.getReader();
          if (!reader) return;

          const decoder = new TextDecoder();

          while (true) {
            const { value, done } = await reader.read();
            if (done) break;

            // Decode the chunk of data received from the stream
            const chunk = decoder.decode(value, { stream: true });

            // Split the chunk by lines to handle individual messages
            const events = chunk.split('\n').filter(Boolean);
            for (const event of events) {
              if (event.startsWith('data: ')) {
                const message = event.substring('data: '.length).trim();

                try {
                  // Check if the message is using single quotes and convert to double quotes if needed
                  const validJsonMessage = message.replace(/'/g, '"');

                  // Parse the JSON data
                  const parsedData = JSON.parse(validJsonMessage);

                  // Update in-progress workflows
                  dispatch(updateTickets(parsedData))
                } catch (error) {
                  console.error("Error parsing SSE data:", error);
                }
              }
            }
          }
        } catch (error) {
          console.error("Error connecting to SSE:", error);
        } finally {
          // Reset connection status
          isTicketConnected.current = false;
        }
      };

      fetchData();

      // Cleanup function to reset connection status on unmount
      return () => {
        isTicketConnected.current = false;
      };

    }
  }, [loadingTickets])

  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
