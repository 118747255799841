// Utility function to format date in American format (MM-DD-YYYY)
export const FormatDate = (dateString: string) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
};

// Utility function to format date in American format (Month DD, YYYY)
export const FormatDateString = (date: Date) => {
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long', // Full month name (e.g., October)
        day: '2-digit' // Day with leading zero if needed
    });
};


// Utility function to format Day
export const FormatDay = (date: Date) => {
    const day = date.toLocaleDateString(undefined, { weekday: 'long' });

    // Capitalize the first letter of the day
    return day.charAt(0).toUpperCase() + day.slice(1);
};



// Utility function to format time in AM/PM
export const FormatTime = (dateString: string) => {
    const date = new Date(dateString);
    let hours = date?.getHours();
    const minutes = date?.getMinutes()?.toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12;  // Convert to 12-hour format
    return `${hours}:${minutes} ${ampm}`;
};

// Utility function to get initials of processor name
export const GetInitials = (name: string) => {
    return name?.split(' ')?.map(word => word.charAt(0).toUpperCase()).join('');
};

// Utility function to format status
export const FormatStatus = (status: string, current_task?: string) => {

    if (status === "in_progress" && current_task === '') {
        status = "scheduled";
    }

    return status?.replace('_', ' ')?.replace(/\b\w/g, char => char.toUpperCase());  // Capitalize first letter
};

export const FormatName = (str: string) => {
    return str
        .split('_') // Split the string by underscores
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(' '); // Join the words with a space
}


export const GetGMTTime = (): string => {
    const now = new Date();

    // Function to add leading zero for consistent formatting
    const padZero = (num: number) => (num < 10 ? '0' + num : num);

    // Get time parts in GMT/UTC
    const year = now.getUTCFullYear();
    const month = padZero(now.getUTCMonth() + 1); // Months are zero-indexed
    const date = padZero(now.getUTCDate());
    const hours = padZero(now.getUTCHours());
    const minutes = padZero(now.getUTCMinutes());
    const seconds = padZero(now.getUTCSeconds());

    // Combine into a formatted string (e.g., "2024-10-08T14:30:00")
    return `${year}-${month}-${date}T${hours}:${minutes}:${seconds}`;
};


// Function to calculate the remaining time in TypeScript
export function CalculateRemainingTime(startTimeStr: string, allocatedTimeInSeconds: number): string {
    // Parse start time into a Date object
    const startTime = new Date(startTimeStr);

    // Get the current time as an ISO string in UTC/GMT using GetGMTTime
    const currentGMTString = GetGMTTime();
    const currentGMT = new Date(currentGMTString);

    // Calculate the difference in seconds between current GMT and start time
    const timeDifferenceInSeconds = Math.floor(currentGMT.getTime() / 1000 - startTime.getTime() / 1000);

    // Calculate remaining time in seconds
    const remainingTimeInSeconds = allocatedTimeInSeconds - timeDifferenceInSeconds;

    // Ensure remaining time is not negative
    const clampedRemainingTime = Math.max(0, remainingTimeInSeconds);

    // Convert remaining seconds into hours, minutes, and seconds
    const hours = Math.floor(clampedRemainingTime / 3600);
    const minutes = Math.floor((clampedRemainingTime % 3600) / 60);
    const seconds = clampedRemainingTime % 60;

    // Format the output as "0h:0m:0s"
    return `${hours}h:${minutes}m:${seconds}s`;
}

export function CalculateCompletedTime(startTimeStr: string, endTimeStr: string): string {
    console.log(startTimeStr, endTimeStr)
    // Parse start time and end time into Date objects
    const startTime = new Date(startTimeStr);
    const endTime = new Date(endTimeStr);

    // Calculate the difference in seconds between end time and start time
    const timeDifferenceInSeconds = Math.floor(endTime.getTime() / 1000 - startTime.getTime() / 1000);

    // Ensure that time difference is not negative (just in case)
    const clampedTimeDifference = Math.max(0, timeDifferenceInSeconds);

    // Convert the difference into hours, minutes, and seconds
    const hours = Math.floor(clampedTimeDifference / 3600);
    const minutes = Math.floor((clampedTimeDifference % 3600) / 60);
    const seconds = clampedTimeDifference % 60;

    // Format the output as "0h:0m:0s"
    return `Finished in ${hours}h:${minutes}m:${seconds}s`;
}