import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Import images
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";

// Import Components
import FullScreenDropdown from '../Components/Common/FullScreenDropdown';
import NotificationDropdown from '../Components/Common/NotificationDropdown';
import ProfileDropdown from '../Components/Common/ProfileDropdown';

import { changeSidebarVisibility } from '../slices/thunks';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';

const Header = ( headerClass ) => {
    const dispatch = useDispatch();

    const selectSidebarVisibility = createSelector(
        (state) => state.Layout,
        (layout) => layout.sidebarVisibility // Adjust this according to your state structure
    );

    // Inside your component
    const sidebarVisibilityType = useSelector(selectSidebarVisibility);

    const toggleMenuBtn = () => {
        const windowSize = document.documentElement.clientWidth;
        const hamburgerIcon = document.querySelector(".hamburger-icon")
        dispatch(changeSidebarVisibility("show"));

        if (windowSize > 767) {
            hamburgerIcon.classList.toggle('open');
        }

        // For collapse horizontal menu
        if (document.documentElement.getAttribute('data-layout') === "horizontal") {
            document.body.classList.toggle("menu");
        }

        // For collapse vertical and semi-box menu
        if (sidebarVisibilityType === "show" && 
            (document.documentElement.getAttribute('data-layout') === "vertical" || 
             document.documentElement.getAttribute('data-layout') === "semibox")) {
                 
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove('vertical-sidebar-enable');
                document.documentElement.setAttribute('data-sidebar-size', 
                    document.documentElement.getAttribute('data-sidebar-size') === 'sm' ? '' : 'sm');
            } else if (windowSize > 1025) {
                document.body.classList.remove('vertical-sidebar-enable');
                document.documentElement.setAttribute('data-sidebar-size', 
                    document.documentElement.getAttribute('data-sidebar-size') === 'lg' ? 'sm' : 'lg');
            } else if (windowSize <= 767) {
                document.body.classList.add('vertical-sidebar-enable');
                document.documentElement.setAttribute('data-sidebar-size', 'lg');
            }
        }

        // Two column menu
        if (document.documentElement.getAttribute('data-layout') === "twocolumn") {
            document.body.classList.toggle('twocolumn-panel');
        }
    };

    return (
        <React.Fragment>
            <header id="page-topbar" className={headerClass}>
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box horizontal-logo">
                                <Link to="/" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoDark} alt="" height="17" />
                                    </span>
                                </Link>

                                <Link to="/" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoLight} alt="" height="17" />
                                    </span>
                                </Link>
                            </div>

                            <button
                                onClick={toggleMenuBtn}
                                type="button"
                                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none"
                                id="topnav-hamburger-icon">
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>
                        </div>

                        <div className="d-flex align-items-center">
                            {/* FullScreenDropdown */}
                            <FullScreenDropdown />

                            {/* NotificationDropdown */}
                            <NotificationDropdown />

                            {/* ProfileDropdown */}
                            <ProfileDropdown />
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default Header;
