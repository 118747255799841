
import TableContainer from 'Components/Common/TableContainerReactTable';
import React, { useMemo } from 'react';

export const GetTickets = ({ tickets }: any) => {

    const columns = useMemo(
        () => [
            {
                header: "Ticket ID",
                accessorKey: "ticket_id",
                enableColumnFilter: false,
            },
            {
                header: "Workflow ID",
                accessorKey: "workflow_id",
                enableColumnFilter: false,
            },
            {
                header: "Task ID",
                accessorKey: "task_id",
                enableColumnFilter: false,
            },
            {
                header: "Error Description",
                accessorKey: "error",
                enableColumnFilter: false,
            },
            {
                header: "Created At",
                accessorKey: "created_at",
                enableColumnFilter: false,
            },
            {
                header: "Status",
                accessorKey: "ticket_status",
                enableColumnFilter: false,
                cell: (cell: any) => {
                    switch (cell.getValue()?.toLowerCase()) {
                        case "solved":
                            return (<span className="capitalize badge bg-success-subtle border-2 border-success text-success text-capitlise"> {cell.getValue()}</span>);
                        case "failed":
                            return (<span className="capitalize badge bg-danger-subtle border-2 border-danger text-danger text-capitlise"> {cell.getValue()}</span>);
                        case "open":
                            return (<span className="capitalize badge bg-info-subtle border-2 border-info text-info text-capitlise"> {cell.getValue()}</span>);
                        default:
                            return (<span className="capitalize badge bg-warning-subtle border-2 border-warning text-warning text-capitlise"> {cell.getValue()}</span>);
                    }
                },
            }
        ],
        []
    );

    return (
        <React.Fragment >
            <TableContainer
                columns={(columns || [])}
                data={(tickets || [])}
                customPageSize={5}
                showPagination={false}
                clickable={false}
            />
        </React.Fragment >
    );
}