import React, { useEffect, useState, useRef } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'

import { useSelector } from 'react-redux';
// import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { WorkflowList } from './WorkflowList';
import { WorkflowHistory } from './WorkflowHistory';

import { useDispatch } from 'react-redux';
import { fetchWorkflows } from 'slices/workflow/reducer';
// import Utilities
import {
    FormatName,
    FormatDate,
    FormatTime,
    FormatStatus,
    GetInitials
} from 'helpers/Formatters/Format';

const Workfow = () => {
    document.title = "Workflow | TFS Processing App";
    const [completedWorkflows, setCompletedWorkflows] = useState([]);
    const [inProgressWorkflows, setInProgressWorkflows] = useState([]);
    const { workflows, loading } = useSelector((state) => state.Workflow); // Adjust to your root reducer structure
    const dispatch = useDispatch()

    useEffect(() => {
        if (workflows && !loading) {
            const { completed, inProgress } = separateWorkflowsByStatus(workflows);

            setCompletedWorkflows(completed)
            setInProgressWorkflows(inProgress)
        }
    }, [loading, workflows])

    useEffect(() => {
        dispatch(fetchWorkflows());
    }, [])

    const separateWorkflowsByStatus = (response) => {
        const completed = [];
        const inProgress = [];

        response?.forEach(item => {
            const formattedWorkflow = {
                name: FormatName(item?.workflow_display_name),  // Convert workflow_name to title case
                date: item?.start_time !== "" ? FormatDate(item?.start_time) : "",  // Format start_time to MM-DD-YYYY
                start_time: item?.start_time !== "" ? FormatTime(item?.start_time) : "",  // Format time to AM/PM
                end_time: item?.end_time !== "" ? FormatTime(item?.end_time) : "",  // Format time to AM/PM
                processor: GetInitials(item?.processor_name),  // Get initials from processor_name
                current_Task: item?.current_running_task || '',  // Handle missing current_running_task
                status: item?.status !== "" ? FormatStatus(item?.status, item?.current_running_task) : "",
                tasks: item?.tasks,            // Format status
                allocated_time: item?.allocated_time,
                started_time: item?.start_time,
                workflow_id: item?.id
            };

            if (item?.status === 'completed') {
                completed.push(formattedWorkflow);
            } else {
                inProgress.push(formattedWorkflow);
            }
        });

        return { completed, inProgress };
    };

    return (
        <React.Fragment>
            {loading ? (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            ) :
                (<div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Workflow" pageTitle="Workflow" action="/workflow" />
                        {/* Workflow List */}

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardHeader>
                                        <h3 className="card-title mb-0 capitalize">Active Workflows</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <WorkflowList workflowData={inProgressWorkflows} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        {/* Workflow History */}
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardHeader>
                                        <h3 className="card-title mb-0 ">Completed Workflows</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <WorkflowHistory workflowData={completedWorkflows} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>)}
        </React.Fragment>
    );
};

export default Workfow;
