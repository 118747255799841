import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { Card, CardBody, CardHeader, Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane, Button, Label } from 'reactstrap';
import classnames from 'classnames';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { ActionList } from './ActionList';
import axios from 'axios';
import { FormatName, FormatTime } from 'helpers/Formatters/Format';
import { fetchEventSource } from '@microsoft/fetch-event-source';

// type ActionData = {
//     action: string;
//     task_name: string;
//     type: string;
//     time: string;
//     time_elapsed: string;
//     task_uuid: string;
// };

const Action = () => {
    document.title = "Workflow | TFS Processing App";

    const [activeTab, setActiveTab] = useState('1');
    const [openTab, setOpenTab] = useState(false);
    const [actionsData, setActionsData] = useState([]);
    const [actionName, setActionsName] = useState("");
    const [actionTaskId, setActionTaskId] = useState("");
    const [actionUrl, setActionUrl] = useState("");
    const [loading, setLoading] = useState(true)

    // Navigate for redirecting
    const navigate = useNavigate();

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    useEffect(() => {

        const fetchData = async () => {
            try {
                const url = `${window.env.REACT_APP_BASE_URL}/workflow/manualTasks`;  // Replace with your URL
                // const url = 'http://localhost:8000/workflow/manualTasks';
                const token = sessionStorage.getItem('authToken')

                // Make the GET request with the correct URL and headers
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (Array.isArray(response)) {
                    // Map each task in the array to the ActionData structure
                    const newActions = response?.map((task) => ({
                        action: FormatName(task.data?.workflow_name),
                        task_name: FormatName(task?.data?.task_name),
                        type: "Inbound",
                        time: FormatTime(task?.data?.started_at),
                        time_elapsed: "",
                        task_uuid: task?.data.task_uuid, // For uniqueness
                        task_url: task?.data.action_url
                    }));
                    setActionsData(newActions)

                    setLoading(false)
                }
            } catch (error) {
                // Handle any errors that occur during the request
                console.error('Error fetching data:', error);
            }
        };
        fetchData()
    }, [])

    const handleStartButtonClick = async (human_task_id, actionName) => {
        const token = sessionStorage.getItem('authToken')

        if (token) {
            try {
                axios.post(`${window.env.APP_BASE_URL}${actionUrl}`, actionName.toLowerCase().includes('start') ?
                    JSON.stringify({
                        start_workflow_human_task_id: human_task_id
                    }) :
                    JSON.stringify({
                        end_workflow_human_task_id: human_task_id
                    }), {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (actionName.toLowerCase().includes('start')) {
                    navigate('/workflow')
                }

                setLoading(false)
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    console.error('Error message:', error.message);
                } else {
                    console.error('Unexpected error:', error);
                }
            }
        }
    };

    const isConnected = useRef(false);

    useEffect(() => {
        const token = sessionStorage.getItem("authToken");

        // Check if the token is available
        if (!token) {
            console.error("No auth token found");
            return; // Exit if there's no token
        }

        if (isConnected.current) return; // Prevent multiple connections

        const fetchData = async () => {
            await fetchEventSource(`${window.env.REACT_APP_BASE_URL}/workflow/manualTask/stream`, {
                headers: {
                    Accept: "text/event-stream",
                    Authorization: `Bearer ${token}`
                },
                onopen(res) {
                    if (res.ok && res.status === 200) {
                        console.log("Connection made", res);
                        isConnected.current = true; // Set to true when connected
                    } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
                        console.log("Client-side error", res);
                    }
                },
                onmessage(event) {
                    if (event?.data) {
                        try {
                            // Replace single quotes with double quotes for valid JSON (if necessary)
                            const sanitizedData = event?.data.replace(/'/g, '"');

                            // Parse the event data (assuming it's now valid JSON)
                            const tasks = JSON.parse(sanitizedData);
                            // Ensure the received data is an array
                            if (Array.isArray(tasks)) {
                                // Map each task in the array to the ActionData structure
                                const newActions = tasks?.map((task) => ({
                                    action: FormatName(task.data?.workflow_name),
                                    task_name: FormatName(task?.data?.task_name),
                                    type: "Inbound",
                                    time: FormatTime(task?.datetime),
                                    time_elapsed: "",
                                    task_uuid: task?.data.task_uuid, // For uniqueness
                                    task_url: task?.data.action_url
                                }));

                                setActionsData(newActions)
                                setLoading(false)
                            } else {
                                console.error("Unexpected data structure:", tasks);
                            }
                        } catch (error) {
                            console.error("Failed to parse event data:", error);
                        }
                    }
                },
                onclose() {
                    console.log("Connection closed by the server");
                    isConnected.current = false; // Reset on close
                },
                onerror(err) {
                    console.log("Error from server", err);
                    isConnected.current = false; // Reset on error
                },
            });
        };

        fetchData();

        // Cleanup function to reset connection status on unmount
        return () => {
            isConnected.current = false;
        };
    }, []);

    return (
        <React.Fragment>
            {loading ? (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            ) : (<div className="page-content relative">
                <Container fluid>
                    <BreadCrumb title="Action List" pageTitle="Action summary" />

                    {openTab && (
                        <div className='fixed w-full h-full ml-32 left-96 flex justify-end z-20'>
                            <div className='h-full w-3/4'>
                                <Card className='w-1/2'>
                                    <CardHeader>
                                        <i
                                            className="ri-close-fill me-4 text-danger rounded-2 p-1"
                                            style={{ backgroundColor: "#FCE8E4", cursor: "pointer" }}
                                            onClick={() => setOpenTab(false)}
                                        ></i>
                                        ACTION
                                    </CardHeader>
                                    <CardBody>
                                        <div className="d-flex justify-content-between">
                                            <Label>{actionName}</Label>
                                            <Button
                                                style={{ background: '#31506A' }}
                                                onClick={() => handleStartButtonClick(actionTaskId, actionName)} // Call the handler
                                            >
                                                Start
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    )}
                    <Row className='relative'>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Nav tabs className="nav-tabs-custom nav-success mb-3">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '1' })}
                                                onClick={() => toggle('1')}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                ALL
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '2' })}
                                                onClick={() => toggle('2')}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                PENDING
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <ActionList setActionTaskId={setActionTaskId} setActionUrl={setActionUrl} setActionsName={setActionsName} actionsData={actionsData} setActionsData={setActionsData} setOpenTab={setOpenTab} />
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <p>No pending actions available.</p>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>)}
        </React.Fragment>
    );
};

export default Action;