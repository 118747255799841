import React from "react";
import { Navigate } from "react-router-dom";

//login
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// Workflow
import Workflow from "../pages/Workflow";
import InboundPaymentProcessing from "pages/Workflow/InboundPaymentProcessing";
import Action from "pages/Actions";
import Ticket  from "pages/Tickets";

const authProtectedRoutes = [
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  {
    path: "/action",
    component: <Action />
  },
  {
    path: "/tickets",
    component: <Ticket />
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
  { path: "/workflow", component: <Workflow /> },
  { path: "/inbound-payment-processing", component: <InboundPaymentProcessing /> }
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };