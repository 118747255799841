import React, { useState, useEffect } from 'react';

const Timer = ({ startedTime, allocatedTime }) => {

  // Append 'Z' if startedTime is missing it to ensure it's treated as UTC
  if (startedTime && !startedTime?.endsWith('Z')) {
    startedTime += 'Z';
  }

  // Convert the startedTime to milliseconds since epoch (UTC/GMT)
  const startTimeInMs = new Date(startedTime).getTime();

  // Function to calculate the remaining time left based on GMT
  const calculateTimeLeft = () => {
    const currentTime = Date.now(); // Current time in GMT
    const elapsedSeconds = Math.floor((currentTime - startTimeInMs) / 1000); // Calculate elapsed time in seconds
    return Math.max(allocatedTime - elapsedSeconds, 0); // Calculate remaining time, ensure it doesn't go negative
  };

  // Set the initial state for time left
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  // Update the time left every second
  useEffect(() => {
    // Update the timer every 1 second
    const timerId = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timerId);
  }, [startTimeInMs, allocatedTime]);

  // Convert seconds to mm:ss format
  // Convert seconds to hh:mm:ss or mm:ss format
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600); // Calculate hours
    const minutes = Math.floor((seconds % 3600) / 60); // Remaining minutes after accounting for hours
    const secs = seconds % 60; // Remaining seconds

    if (hours > 0) {
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs
        .toString()
        .padStart(2, '0')}`; // Format as hh:mm:ss
    } else {
      return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`; // Format as mm:ss
    }
  };


  // Calculate the stroke-dasharray and stroke-dashoffset values for the SVG circle
  const radius = 50; // Radius of the circle
  const circumference = 2 * Math.PI * radius; // Circumference of the circle
  const strokeDashoffset = circumference * (1 - timeLeft / allocatedTime); // Calculate offset as time decreases

  // Define the gradient colors
  // const gradientColors = ['#37576F', '#65B5B1'];
  const gradientColors = ['#37576F'];

  // Calculate the gradient stop offset based on the remaining time
  const gradientStopOffset = (timeLeft / allocatedTime) * 100;

  return (
    <div style={{ width: '90px', height: '90px', position: 'relative' }}>
      <svg width="100%" height="100%" viewBox="0 0 120 120">
        {/* Background circle (grey) */}
        <circle
          cx="60"
          cy="60"
          r={radius}
          stroke="#e6e6e6"
          strokeWidth="6"
          fill="none"
        />
        {/* Foreground circle (the one that becomes white gradually) */}
        <circle
          cx="60"
          cy="60"
          r={radius}
          stroke="url(#gradient)"
          strokeWidth="6"
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="butt"
          transform="rotate(-90 60 60)"
        />
        {/* Gradient definition */}
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={gradientColors[0]} />
            <stop offset={`${gradientStopOffset}%`} stopColor={gradientColors[0]} />
          </linearGradient>
        </defs>
      </svg>
      {startedTime ? (<div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <div className="text-sm font-semibold">
          {formatTime(timeLeft)}
        </div>
        <div style={{ fontSize: '10px' }}>Left</div>
      </div>) : (<div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      ><p className='text-gray-400 mt-1'>To Start</p></div>)}
    </div>
  );
};

export default Timer;
