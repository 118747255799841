import React from "react";
import { Container } from "reactstrap";


const Ticket = () => {
    return <React.Fragment>
        <div className="page-content">
            <Container fluid>
                Tickets
            </Container>
        </div>

    </React.Fragment>
}

export default Ticket