import React, { useEffect, useState } from 'react';
import {
  Card, CardBody, CardHeader, Col, Container, Row
} from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from 'react-redux';

// import Components
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useLocation } from 'react-router-dom';
import Timer from '../../../Components/Common/Timer';
import axios from 'axios';
import { GetStatiscs } from './Statistics';
import { GetTickets } from './Tickets';
import { useSelector } from 'react-redux';
import { TaskData } from './Task';
import { fetchTickets } from 'slices/tickets/reducer';

const InboundPaymentProcessing = () => {
  const location = useLocation();
  const dispatch = useDispatch()
  const state = location?.state;
  const [stats, setStats] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [workflowStatus, setWorkflowStatus] = useState("");
  const [ticketsData, setTicketsData] = useState([]);
  const [openTab, setOpenTab] = useState({ open: false, disabled: false });
  const [startTime, setStartTime] = useState("")
  const { tickets, loadingTickets } = useSelector((state) => state.Ticket); // Adjust to your root reducer structure

  document.title = `${state?.key?.name} | TFS Processing App`;

  const { workflows, loading } = useSelector((state) => state.Workflow); // Adjust to your root reducer structure

  // Utility to get workflow by ID
  const getWorkflowById = (workflows, targetId) => workflows?.find((workflow) => workflow?.id === targetId);

  // Utility to get tasks by workflow ID
  const getTasksByWorkflowId = (workflows, targetId) => {
    const workflow = getWorkflowById(workflows, targetId);
    return workflow ? workflow?.tasks : [];
  };

  // Utility to get workflow status by ID
  const getWorkflowStatus = (workflows, targetId) => {
    const workflow = getWorkflowById(workflows, targetId);
    return workflow ? workflow.status : "";
  };

  useEffect(() => {
    if (workflows && !loading) {
      const targetId = state?.key?.workflow_id;
      const newTasks = getTasksByWorkflowId(workflows, targetId);

      // Only update tasks if they have changed
      if (JSON.stringify(newTasks) !== JSON.stringify(tasks)) {
        setTasks(newTasks);

        // Re-enable the button only if required (e.g., when new tasks are fetched)
        setOpenTab((prev) => ({ ...prev, disabled: false }));
        // localStorage.setItem(`disabledState_${targetId}`, JSON.stringify(false));
      }

      // Get Worklfow's Updated StartTime
      const matchingWorkflow = getWorkflowById(workflows, targetId)
      if (matchingWorkflow?.start_time != "" && matchingWorkflow?.start_time != startTime) {
        setStartTime(matchingWorkflow?.start_time)
      }

      const status = getWorkflowStatus(workflows, targetId);
      setWorkflowStatus(status);

      const foundTask = newTasks.find(task => task?.key_name === "bundle_creation");
      if (foundTask && foundTask.status?.toLowerCase() === 'completed') {
        const foundTaskArray = Array.isArray(foundTask?.result) ? foundTask?.result : [foundTask?.result];
        setStats((prevStats) => (prevStats !== foundTaskArray ? foundTaskArray : prevStats));
      }
    }
  }, [workflows, loading, state]);

  // Fetch statistics data from API
  const getStatisticsAPI = async (workflow_id) => {
    const token = sessionStorage.getItem('authToken');
    if (token) {
      try {
        const response = await axios.get(`${window.env.APP_BASE_URL}/workflow/getStatistics?workflowid=${workflow_id}`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });

        const { data } = response;

        const workflow = getWorkflowById(workflows, workflow_id);
        if (workflow && Array.isArray(workflow.tasks)) {
          const bundleTask = workflow?.tasks?.find(task => task?.key_name === "bundle_creation");
          if (bundleTask) {
            const results = data
              ?.filter(item => item?.task_id === bundleTask?.id && item?.result)
              .map(item => Array.isArray(item?.result) ? item.result : [item.result]);

            if (results?.length > 0 && stats !== results) {
              setStats(results);
            }
          }
        }
      } catch (error) {
        console.error(axios.isAxiosError(error) ? error.message : 'Unexpected error:', error);
      }
    }
  };

  useEffect(() => {
    getStatisticsAPI(state?.key?.workflow_id);
    setTasks(state?.key?.tasks);
    setWorkflowStatus(state?.key?.status || '');
  }, [state]);

  const handleStartButtonClick = async (human_task_id, actionPath, actionName) => {
    const token = sessionStorage.getItem('authToken');

    setOpenTab({ disabled: true, open: false });

    // Save the disabled state to localStorage
    const workflow_id = state?.key?.workflow_id;

    localStorage.setItem(`disabledState_${workflow_id}`, JSON.stringify(true));

    if (token) {
      try {
        await axios.post(
          `${window.env.APP_BASE_URL}${actionPath}`,
          JSON.stringify({
            [actionName === "upload" ? "start_upload_bundle_task_id"
              : actionName === "start" ? "create_bundle_task_id"
                : "end_workflow_human_task_id"]: human_task_id
          }), {
          headers: { 'Authorization': `Bearer ${token}` },
        });

        if (actionName === "start") {
          toast.success("Workflow Started Sucessfully !", {
            position: "top-center"
          });

        } else if (actionName === "upload") {
          toast.success("Bundle Uploaded Sucessfully !", {
            position: "top-center"
          });
        } else {
          toast.success("Bundle Approved Sucessfully !", {
            position: "top-center"
          });
        }

      } catch (error) {
        console.error(axios.isAxiosError(error) ? error.message : 'Unexpected error:', error);
        localStorage.setItem(`disabledState_${workflow_id}`, JSON.stringify(false));
      }
    }
  };

  useEffect(() => {
    if (state?.key?.started_time != "") {
      setStartTime(state?.key?.started_time)
    }

    // Get Tickets and Filter them by workflow ID
    dispatch(fetchTickets());
  }, [])

  useEffect(() => {
    if (tickets && !loadingTickets) {

      const targetId = state?.key?.workflow_id;

      const matchingTickets = ((tickets, targetId) => {
        return tickets.filter(ticket => ticket.workflow_id === targetId);
      })(tickets, targetId)

      setTicketsData(matchingTickets)
    }
  }, [loadingTickets, tickets, state])

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <div
          className={`slider-content w-5/12 h-full transition-all duration-700 ${openTab.open ? 'translate-x-0 opacity-100' : ' opacity-0 translate-x-full'
            }`}
        >
          <div className='h-[75%] bg-[#F2F2F7] pl-3'>
            <div className='bg-white border-1 border-gray-200 py-2 h-full rounded-lg  mb-10'>
              <div className='flex gap-3 items-center px-4 py-2'>
                <div onClick={() => setOpenTab({ open: false })} className='cursor-pointer'>
                  <i className="ri-close-line font-extralight bg-red-100 p-2 rounded-md text-red-500"></i>
                </div>
                <p className='uppercase font-medium text-sm m-0'>{openTab.action_name}</p>
              </div>
              <hr className='mt-2 text-gray-400' />
              <div className='px-2 flex flex-col h-5/6 justify-between'>
                <div className='flex-col gap-4'>
                  <div className='flex-col gap-2 px-3'>
                    <div className='flex w-3/4 pb-2'>
                      <span className='font-semibold  w-1/2'>Task Name: </span>
                      <span>{openTab?.task_name}</span>
                    </div>
                    <div className='flex w-3/4 pb-2'>
                      <span className='font-semibold w-1/2'>Task ID: </span>
                      <span>{openTab?.id}</span>
                    </div>
                    <div className='flex w-3/4'>
                      <span className='font-semibold w-1/2'>Workflow ID: </span>
                      <span>{state?.key?.workflow_id}</span>
                    </div>
                  </div>
                  {tasks?.some(task => task?.key_name === "bundle_creation" && task?.status === "completed") && (
                    <Card className='border-1 mt-4'>
                      <CardHeader>
                        <h5 className="card-title mb-0">Statistics</h5>
                      </CardHeader>
                      <CardBody className='text-xs'>
                        <GetStatiscs statistics={stats} />
                      </CardBody>
                    </Card>)
                  }
                  {tasks?.some(task => task?.key_name === "upload_bundle" && task?.status === "completed") && (
                    <div className='flex gap-2 flex-col'>
                      <p className='m-0'>Attachments</p>
                      <p className='font-semibold p-0 m-0'>File 1</p>
                      <div className='flex items-center flex-row justify-between'>
                        <div class="border border-gray-300 rounded-md w-[90%]">
                          <input type="file" id="file-upload" class="hidden" />
                          <label for="file-upload"
                            className='bg-[#31506A] col-start-3 shadow-inner-all-sides m-0 text-xs text-white font-bold py-2 px-3 rounded-rtb-md border-rtb-[0.09px]  border-[#51718b] transition-all duration-200 active:bg-[#31506A] active:shadow-none'
                          >
                            Choose File
                          </label>
                          <input type="text" id="file-name" placeholder="No file chosen" class="ml-2 flex-1 border-none focus:ring-0 focus:outline-none" readonly />
                        </div>
                        <i className="ri-add-circle-line font-extralight text-xl "></i> </div>
                    </div>)}
                </div>
                <div className='flex justify-end gap-2'>
                  <button
                    disabled={openTab.disabled}
                    className='bg-white shadow-gray-300 shadow-inner-all-sides text-xs font-bold py-1 px-3 rounded-md border-2 border-[#DBDBDB] transition-all duration-200 active:bg-gray-400 active:shadow-none'
                    onClick={() => setOpenTab({ open: false })}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={openTab.disabled}
                    className='bg-[#31506A] shadow-inner-all-sides text-xs text-white font-bold py-1 px-3 rounded-md border-[0.09px] border-[#51718b] transition-all duration-200 active:bg-[#31506A] active:shadow-none'
                    onClick={() => handleStartButtonClick(openTab.id, openTab.action_path, openTab.action)}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
        <Container fluid>
          <BreadCrumb title={state?.key?.name} pageTitle="Workflow" action="/workflow" />
          {/* Workflow List */}
          <Row>
            <Col lg={12}>
              <Card className='border-none'>
                <div className="grid grid-cols-12 items-start pt-2">
                  <CardHeader className='col-span-11'>
                    <h5 className="card-title mb-0 capitalize">Tasks</h5>
                  </CardHeader>
                  {workflowStatus && workflowStatus.toLowerCase() !== "completed" &&
                    <div className="place-self-center">
                      <Timer startedTime={startTime} allocatedTime={state?.key?.allocated_time} />
                    </div>}
                </div>
                {tasks?.map((task) => (
                  <TaskData key={task?.id} taskData={task} workflowStatus={workflowStatus} setOpenTab={setOpenTab} openTab={openTab} />
                ))}
              </Card>
            </Col>
          </Row>
          {/* Statistics */}
          {tasks?.some(task => task?.key_name === "bundle_creation" && task?.status === "completed") && (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Statistics</h5>
                  </CardHeader>
                  <CardBody>
                    <GetStatiscs statistics={stats} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Tickets</h5>
                </CardHeader>
                <CardBody>
                  <GetTickets tickets={ticketsData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InboundPaymentProcessing;
