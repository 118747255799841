import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';


// Initial state
const initialState = {
    workflows: [], // Single global state to hold all workflows
    loading: false,
    error: null,
};

// Thunk for fetching workflows from the API
export const fetchWorkflows = createAsyncThunk(
    'workflow/fetchWorkflows',
    async (_, { rejectWithValue }) => {
        try {
            const token = sessionStorage.getItem('authToken');
            const url = `${window.env.REACT_APP_BASE_URL}/workflow/getWorkflows`;
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response; // Assuming the API response returns the workflows directly
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const workflowSlice = createSlice({
    name: 'workflow',
    initialState,
    reducers: {
        // Action to update workflows from SSE data
        updateWorkflows: (state, action) => {
            const updatedWorkflows = action.payload; // This should be an array of updated workflow objects

            // Create a map of existing workflows by ID for quick lookup
            const existingWorkflowsMap = new Map(state.workflows.map(workflow => [workflow.id, workflow]));

            updatedWorkflows.forEach((updatedWorkflow) => {
                if (existingWorkflowsMap.has(updatedWorkflow.id)) {
                    // Update the existing workflow
                    existingWorkflowsMap.set(updatedWorkflow.id, {
                        ...existingWorkflowsMap.get(updatedWorkflow.id),
                        ...updatedWorkflow // Merge existing and updated properties
                    });
                }
            });



            // Convert the map back to an array and update the state
            state.workflows = Array.from(existingWorkflowsMap.values())
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkflows.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchWorkflows.fulfilled, (state, action) => {
                state.workflows = action.payload; // Store all workflows in the global state
                state.loading = false;
            })
            .addCase(fetchWorkflows.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            });
    },
});

// Export actions and reducer
export const { updateWorkflows } = workflowSlice.actions;
export default workflowSlice.reducer;
