import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";

// API Key
import APIKeyReducer from "./apiKey/reducer";

// Import the workflow reducer
import workflowReducer from "./workflow/reducer"

// Import the Ticket Reducer
import ticketReducer from "./tickets/reducer"

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    APIKey: APIKeyReducer,
    Workflow: workflowReducer, // Add the workflow reducer here
    Ticket: ticketReducer
});

export default rootReducer;
