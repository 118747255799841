import React, { useMemo } from 'react';
import TableContainer from "../../Components/Common/TableContainerReactTable";

// type ActionData = {
//     Action: string;
//     "Task Name": string;
//     Type: string;
//     Time: string;
//     "Time Elapsed": string;
//     task_uuid: string;
// };

// type Props = {
//     setOpenTab: any;
//     actionsData: any;
//     setActionsData :any;
//     setActionsName: any;
//     setActionTaskId: any;
//     setActionUrl:any;
// };

export const ActionList = ({ setOpenTab, actionsData, setActionsName, setActionTaskId, setActionUrl, setActionsData }) => {
    // const [actionsData, setActionsData] = useState<ActionData[]>([]); // Dynamic data from SSE

    const columns = useMemo(
        () => [
            {
                header: "Action",
                accessorKey: "action",
                enableColumnFilter: false,
            },
            {
                header: "Task Name",
                accessorKey: "task_name",
                enableColumnFilter: false,
            },
            {
                header: "Type",
                accessorKey: "type",
                enableColumnFilter: false,
            },
            {
                header: "Time",
                accessorKey: "time",
                enableColumnFilter: false,
            },
            {
                header: "",
                accessorKey: " ",
                enableColumnFilter: false,
                cell: (cell) => (
                    <div className="d-flex justify-content-between">
                        {cell.getValue() || ''}
                        <i
                            className="ri-skip-forward-mini-line"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setActionUrl(cell.row.original.task_url)
                                setActionTaskId(cell.row.original.task_uuid)
                                setActionsName(cell.row.original.task_name)
                                setOpenTab(true);
                            }}
                        ></i>
                    </div>
                ),
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <TableContainer
                columns={columns}
                data={actionsData} // Use dynamic data here
                customPageSize={20}
                SearchPlaceholder="Search..."
                showPagination={false}
            />
        </React.Fragment>
    );
};
