import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';


// Initial state
const initialState = {
    tickets: [], // Single global state to hold all tickets
    loadingTickets: false,
    error: null,
};

// Thunk for fetching tickets from the API
export const fetchTickets = createAsyncThunk(
    'tickets/getTickets',
    async (_, { rejectWithValue }) => {
        try {
            const token = sessionStorage.getItem('authToken');
            const url = `${window.env.REACT_APP_BASE_URL}/tickets/getTickets`;
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response; // Assuming the API response returns the tickets directly
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const ticketSlice = createSlice({
    name: 'ticket',
    initialState,
    reducers: {
        // Action to update tickets from SSE data
        updateTickets: (state, action) => {
            const updatedTickets = action.payload; // This should be an array of updated ticket objects

            // Create a map of existing tickets by ID for quick lookup
            const existingTicketsMap = new Map(state.tickets.map(ticket => [ticket.ticket_id, ticket]));

            updatedTickets.forEach((updatedTicket) => {
                if (existingTicketsMap.has(updatedTicket.ticket_id)) {
                    // Update the existing ticket
                    existingTicketsMap.set(updatedTicket.ticket_id, {
                        ...existingTicketsMap.get(updatedTicket.ticket_id),
                        ...updatedTicket // Merge existing and updated properties
                    });
                }
            });

            // Convert the map back to an array and update the state
            state.tickets = Array.from(existingTicketsMap.values())
            console.log(state.tickets)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTickets.pending, (state) => {
                state.loadingTickets = true;
            })
            .addCase(fetchTickets.fulfilled, (state, action) => {
                state.tickets = action.payload; // Store all tickets in the global state
                state.loadingTickets = false;
            })
            .addCase(fetchTickets.rejected, (state, action) => {
                state.loadingTickets = false;
                state.error = action?.payload;
            });
    },
});

// Export actions and reducer
export const { updateTickets } = ticketSlice.actions;
export default ticketSlice.reducer;
