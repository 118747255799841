import { CalculateCompletedTime, CalculateRemainingTime, FormatStatus, FormatTime } from "helpers/Formatters/Format";
import { useEffect, useState } from "react";
import { Card, CardBody, Progress } from "reactstrap";

export const TaskData = ({ taskData, workflowStatus, setOpenTab, openTab, workflow_id }: any) => {
    const [active, setActive] = useState<boolean>(false);

    // Helper to determine if the card should be active
    const isActive = () => {
        const { key_name, status } = taskData || {};
        return (
            (key_name === "bundle_creation" && status === "ready") ||
            (key_name === "upload_bundle" && status === "ready") ||
            (key_name === "upload_bundle" && status === "completed" && workflowStatus?.toLowerCase() !== "completed")
        );
    };

    useEffect(() => {
        setActive(isActive());
    }, [taskData?.status, workflowStatus]);

    // Helper to render status badges
    const renderStatusBadge = (status: string) => {
        const statusMap: Record<string, string> = {
            ready: "info",
            completed: "success",
            in_progress: "warning",
            pending: "warning",
            failed: "danger",
        };

        const color = statusMap[status] || "info";
        return (
            <span className={`badge bg-${color}-subtle border-2 border-${color} text-${color} text-capitalize`}>
                {FormatStatus(status)}
            </span>
        );
    };

    // Helper to render action buttons
    const renderActionButton = () => {
        const { key_name, status, id, display_name, action_path } = taskData || {};
        const buttonConfig: any = {
            "bundle_creation:ready": { label: "Start", action: "start", action_name: "Start Processing" },
            "upload_bundle:ready": { label: "Start", action: "upload", action_name: "Start Payment Bundle" },
            "upload_bundle:completed": {
                label: "Approve",
                action: "approve",
                condition: workflowStatus?.toLowerCase() !== "completed",
                action_name: "Approve Payment Bundle"
            },
        };

        const key = `${key_name}:${status}`;
        const config = buttonConfig[key];

        if (config && (config.condition === undefined || config.condition)) {
            return (
                <button
                    className={`shadow-inner-all-sides text-sm font-bold py-1 px-3 rounded-md border-[0.09px] border-[#51718b] ${openTab.disabled ? 'bg-[#355c72] text-gray-300' : 'animate-pulse text-white bg-[#31506A] active:bg-[#5483a9]'}`}
                    onClick={() => setOpenTab({ id, task_name: display_name, action_path, action: config.action, open: true, action_name: config.action_name })}
                    hidden={openTab?.disabled}
                >
                    {config.label}
                </button>
            );
        }
        return null;
    };

    return (
        <CardBody className="mb-0">
            <div className={`${active ? "border-2 border-teal-500 rounded-lg shadow-lg" : "opacity-70"}`}>
                <Card className="bg-light overflow-hidden mb-0 shadow-none">
                    <CardBody className="my-1 mx-2">
                        <div className="grid grid-cols-8 gap-4 content-center">
                            <div className="d-flex align-items-center col-start-1 col-end-3">
                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                    <div className="avatar-title rounded-circle fs-16 text-primary">
                                        <i className="ri-folder-line text-light"></i>
                                    </div>
                                </div>
                                <h6 className="mb-0 font-semibold">{taskData?.display_name}</h6>
                            </div>
                            <p className="mb-0 font-extralight w-14 overflow-hidden truncate place-self-center justify-self-start">
                                {taskData?.id}
                            </p>
                            <div className="d-flex align-items-center gap-2 col-start-4 col-end-6 justify-self-center">
                                <span className="mb-0">
                                    {taskData?.start_time ? FormatTime(taskData?.start_time) : '-'}
                                    {taskData?.end_time ? ` - ${FormatTime(taskData?.end_time)}` : ''}
                                </span>
                            </div>
                            <div className="d-flex align-items-center gap-2 font-semibold">
                                <span className="mb-0">
                                    {taskData?.start_time ?
                                        (taskData?.status !== "completed" ?
                                            CalculateRemainingTime(taskData?.start_time, taskData?.allocated_time) :
                                            CalculateCompletedTime(taskData?.start_time, taskData?.end_time)
                                        ) :
                                        '-'}
                                </span>
                            </div>
                            <div className="place-self-center justify-self-start">
                                {renderStatusBadge(taskData?.status)}
                            </div>
                            <div className="place-self-end">
                                {renderActionButton()}
                            </div>
                        </div>
                    </CardBody>
                    <Progress value={0} color="success" className="bg-success-subtle rounded-0" />
                </Card>
            </div>
        </CardBody>
    );
};
